<template>
    <a-card>
        <a-page-header
            :title='"任务进度"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="任务名称">
                        <a-input
                            v-model="form.missionName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="任务品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="任务状态">
                        <a-select
                            mode="multiple"
                            v-model="form.missionStatusList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option :key="item.id" v-for="item in statusList">{{ item.label }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="项目编码">
                        <a-input-number
                            v-model="form.projectCode"
                            placeholder="请输入"
                            :min="0"
                            style="width: 100%"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => record.missionId"
            :pagination='false'
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'计划'"
                    @onClickBtn="jumpPlanPage(record.missionCode)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'线索进度明细'"
                    @onClickBtn="jumpSchedulePage(record.missionId)"
                    v-if="record.missionStatus != 1"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
import filters from "@/mirror-modules/filters.js";

const statusList = [
    { id: 1, label: "草稿" },
    { id: 2, label: "已创建，未投放" },
    { id: 3, label: "进行中" },
    { id: 4, label: "结束" },
    { id: 5, label: "暂停" },
]

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                projectCode: this.$route.query.projectCode,
                missionStatusList: [2, 3, 5]
            },
            principalList: [],
            statusList,
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '任务编码',
                    dataIndex: 'missionCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '项目编码',
                    dataIndex: 'projectCode',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务名称',
                    dataIndex: 'missionName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务周期',
                    customRender:(text, row) => {
                        return <div>
                            <div>{ row.startTime || '-' }</div>
                            ~
                            <div>{ row.endTime || '-' }</div>
                        </div>
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    scopedSlots: { customRender: 'action' }
                },
                {
                    align: 'center',
                    title: '绑定计划数',
                    dataIndex: 'boundAdPlanCount',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '本日有消费计划数',
                    dataIndex: 'todayCostedPlanCount',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务总/日预算（元）',
                    customRender:(text, row) => {
                        return `${filters.changeNum(row.totalBudget, 2, true)} / ${filters.changeNum(row.dailyBudget, 2, true)}`
                    }
                },
                {
                    align: 'center',
                    title: '任务总消耗（元）',
                    dataIndex: 'totalCost',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? `${filters.changeNum(text, 2, true)}` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务本日消耗（元）',
                    dataIndex: 'todayCost',
                    customRender:(text, row) => {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务KPI类型',
                    customRender:(text, row) => {
                        return row.kpiType == 1 ? '线索量' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务总KPI',
                    dataIndex: 'totalKpi',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务KPI完成数',
                    dataIndex: 'kpiAchievedCount',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '任务KPI单价（元）',
                    dataIndex: 'perKpiCost',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    created() {
        this.getPrincipalList()
        this.getDataList()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                projectCode: this.$route.query.projectCode,
                missionStatusList: [2, 3, 5]
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.deliverManage.getMissionProgressList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpPlanPage(missionCode) {
            this.$router.push({
                path: `/advertisingPlanManage`,
                query: {
                    missionCode
                }
            })
        },
        jumpSchedulePage(id) {
            this.$router.push({
                path: `/taskSchedule/detail`,
                query: {
                    id
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
</style>